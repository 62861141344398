var preselected_type = {
    id: 999999,
    name: 'Please Select',
}


var preselected_prj_type = {
    id: 999999,
    name: 'Please Select',
}

var val = new Date();

var date = val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
var time = val.getHours() + ":" + val.getMinutes()

const default_project = {
    id: null,
    maconomy: '',
    sn: '',
    access_pro: '',
    office: {...preselected_type},
    client: {...preselected_type},
    project_name: '',
    status: {
        id: 14,
        name: 'NEW',
    },

    eta_project: 0,
    eta: 0,
    revised_eta: 0,
    eta_overlay: 0,
    eta_fwsetup: 0,
    eta_FW: 0,
    eta_dp: 0,
    eta_tabs: 0,
    eta_charts: 0,
    eta_cj: 0,
    eta_pm: 0,
    eta_da: 0,
    eta_trm: 0,

    eta_project_ver: 0,
    eta_ver: 0,
    revised_eta_ver: 0,
    eta_overlay_ver: 0,
    eta_fwsetup_ver: 0,
    eta_FW_ver: 0,
    eta_dp_ver: 0,
    eta_tabs_ver: 0,
    eta_charts_ver: 0,
    eta_cj_ver: 0,
    eta_pm_ver: 0,
    eta_da_ver: 0,
    eta_trm_ver: 0,
    
    questions: 1,
    type: {...preselected_type},
    extra_coverage: false,
    owner: {...preselected_type},
    pm_onshore: {...preselected_type},
    pm_offshore: {...preselected_type},
    software: {...preselected_type},
    orion: false,
    archive: false,
    created_on: null,
    subscribers: [],
    url: null,
    attention: {
        id: 1,
        name: 'low'
    },
    sd_plus: null,
    quota_approved: false,
    link_delivery: {date: date, time: time},
    zone: '',
    complexity_new: {
        id: 1,
        name: 'low'
    },
    complexity_notes: "",
    soSubType: {...preselected_type},
    general_type: {...preselected_type},
    edit_me: false,
    unsaved: false,
    num_country: 1,
    billed_on: {date: date, time: time},
}



const available_cols = [
    'sn',
    'maconomy',
    'access_pro',
    'project_name',
    'url',
    'client',
    'office',
    'subs',
    'owner',
    'pm_onshore',
    'pm_offshore',
    'type',
    'status',
    'orion',
    'controls',
    'delivery',
    'last_edit',
    'attention',
    'sd_plus',
    'questions',
    'eta',
    'eta_tabs',
    'eta_charts',
    'quota_approved',
    'complexity',
    'revised_eta',
    'eta_percentages',
    'extra_coverage',
    'spent_hours_admin',
    'spent_hours_overlay',
    'spent_hours_qa',
    'spent_hours_qd',
    'spent_hours_qdqa',
    'spent_hours_qdqa_changes',
    'spent_hours_so',
    'spent_hours_dp',
    'spent_hours_data_validation',
    'spent_hours_cjdp',
    'spent_hours_data_script',
    'spent_hours_tabwork',
    'spent_hours_data_visualization',
    'spent_hours_charting',
    'fast_add_so',
    'general_type'
]

const default_project_filters = {
        owner: [],
        pm_onshore: [],
        pm_offshore: [],
        maconomy: '',
        sn: '',
        access_pro: '',
        client: [],
        top_client: [],
        type: [],
        name: null,
        status: [],
        archived: false,
        sd_plus: null,
        complexity: [],
        office: [],
        extra_coverage: false,
        attention: [],
        orion: false,
        quota_approved: false,
        general_type: [],
        full_search: false,
        tags: [],
}

const default_comment = {
    id: 0,
    scope: {...preselected_type},
    text: '',
    created_on: '12.12.2012',
    attention: {...preselected_type},
    archived: false,
    user_id: 0,
}

export {default_project, default_project_filters, default_comment, available_cols}