const BASE_URL = 'https://wtm.bright-research.com/';
//const BASE_URL = 'http://localhost:8000/';
const URLS = {
    aj_get_init_lists: BASE_URL + "BrightWiki/admin/aj_get_init_lists",
    aj_get_all_projects: BASE_URL + "BrightWiki/ajax/get_all_projects",
    aj_get_all_users: BASE_URL + "BrightWiki/ajax/get_all_users",
    aj_submit_task: BASE_URL + "BrightWiki/ajax/aj_submit_task",
    aj_delete_task: BASE_URL + "BrightWiki/ajax/remove_task",
    aj_get_daily_tasks: BASE_URL + "BrightWiki/ajax/aj_get_daily_tasks",
    ajax_get_tasks_calendar: BASE_URL + "BrightWiki/ajax/get_tasks_calendar",
    aj_delete_project: BASE_URL + "BrightWiki/ajax/del_project",
    aj_create_project: BASE_URL + "BrightWiki/ajax/aj_create_project",
    aj_send_bulk_sdplus: BASE_URL + "BrightWiki/ajax/aj_send_bulk_sdplus",
    aj_bulk_update: BASE_URL + "BrightWiki/ajax/aj_bulk_update",
    aj_update_user_cols: BASE_URL + "BrightWiki/ajax/aj_update_user_cols",
    ajax_get_projects: BASE_URL + "BrightWiki/ajax/get_projects",
    ajax_get_subprojects: BASE_URL + "BrightWiki/ajax/get_subprojects",
    aj_login_ldap: BASE_URL + 'BrightWiki/ajax/aj_login_ldap',
    //aj_login: BASE_URL + 'BrightWiki/ajax/aj_login',
    aj_get_top_client: BASE_URL + 'BrightWiki/ajax/aj_get_top_client', //requires top_client_is, this one returns the project field mappers as well
    aj_get_validation_for_client: BASE_URL + 'BrightWiki/ajax/aj_get_validation_for_client',
    aj_get_project_json: BASE_URL + 'BrightWiki/ajax/get_project_json',
    aj_get_project_history: BASE_URL + 'BrightWiki/ajax/aj_get_project_history',
    aj_set_top_client_fields: BASE_URL + 'BrightWiki/ajax/aj_set_top_client_fields',
    aj_set_top_client_prj_fields: BASE_URL + 'BrightWiki/ajax/aj_set_top_client_prj_fields',
    aj_get_top_client_prj_fields: BASE_URL + 'BrightWiki/ajax/aj_get_top_client_prj_fields',
    client_external: BASE_URL + 'BrightWiki/project/client',
    aj_filtered_report: BASE_URL + 'BrightWiki/ajax/aj_filtered_report',
    aj_archive_project: BASE_URL + 'BrightWiki/ajax/archive_project',
    aj_unarchive_project: BASE_URL + 'BrightWiki/ajax/unarchive_project',
    aj_get_comments: BASE_URL + 'BrightWiki/ajax/get_comments',
    aj_post_comment: BASE_URL + 'BrightWiki/ajax/post_comment',
    aj_del_comment: BASE_URL + 'BrightWiki/ajax/delete_comment',
    aj_get_project_schema: BASE_URL + 'BrightWiki/ajax/get_project_schema',
    aj_get_prj_field_options: BASE_URL + 'BrightWiki/ajax/get_prj_field_options',
    aj_save_user_themes: BASE_URL + 'BrightWiki/ajax/aj_save_user_themes',
    aj_subscribe_to_project: BASE_URL + 'BrightWiki/ajax/subscribe',
    aj_unsusbscribe_from_project: BASE_URL + 'BrightWiki/ajax/unsubscribe',
    aj_submit_alert_mails: BASE_URL + 'BrightWiki/ajax/aj_submit_alert_mails',
    aj_get_alert_mails: BASE_URL + 'BrightWiki/ajax/aj_get_alert_mails',
    aj_get_all_tasks: BASE_URL + 'BrightWiki/ajax/aj_get_all_tasks',

    aj_get_sdplus: BASE_URL + 'BrightWiki/ajax/get_sdplus',
    aj_post_sdplus: BASE_URL + 'BrightWiki/ajax/send_sd_plus',
}

export {URLS}

 